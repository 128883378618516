<template>
  <span
    class="py-1 px-2  rounded-lg white--text"
    :class="statusBackgroundColor"
    style="font-size: 12px"
  >
    {{ statusText }}
  </span>
</template>

<script>
import { USED, EXPIRED, TIME_DOWN, OPENED, TERMINATED } from "../config";

export default {
  name: "QRStatus",
  props: {
    status: {
      type: String,
      required: true
    }
  },
  computed: {
    statusText() {
      return {
        [USED]: "использован",
        [EXPIRED]: "просрочен",
        [TIME_DOWN]: "еще нельзя использовать",
        [TERMINATED]: "Отменен",
        [OPENED]: "можно использовать"
      }[this.status];
    },
    statusBackgroundColor() {
      return {
        [USED]: "red darken-2",
        [EXPIRED]: "orange darken-2",
        [TIME_DOWN]: "grey darken-2",
        [TERMINATED]: "grey darken-2",
        [OPENED]: "green darken-2"
      }[this.status];
    }
  }
};
</script>
